
.addBtn[data-v-0af845aa] {
  width: 100%;
  height: 455px;
  font-size: 100px;
}
.outWardBox[data-v-0af845aa] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: calc(100vh - 280px);
  overflow: auto;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  display: flex;
}
.cardContentBox[data-v-0af845aa] {
  width: 100%;
  height: 100%;
  /*display: flex;*/
  /*justify-content: space-between;*/
}
.contentDetails[data-v-0af845aa] {
  font-size: 15px;
  color: #666666;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.contentDetails div[data-v-0af845aa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  width: 230px;
  text-align: left;
}
.contentDetails span[data-v-0af845aa] {
  /*width: 100px;*/
  /*text-align: right;*/
  display: block;
}
.buttonBox[data-v-0af845aa]{
   width: 100%;
   height: 100%;
}
.inWardBoxDevice[data-v-0af845aa] {
  width: 24%;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 5px #dde9ef;
          box-shadow: 0 0 5px #dde9ef;
  border: 1px solid #e7eff3;
  margin-top: 16px;
  margin-right: 0.2%;
  margin-bottom: 0px;
  margin-left: 0.2%;
  text-align: center;
  border-radius: 10px;
  /*height: 50%;*/
  overflow: hidden;
  position: relative;
}
.labelBox[data-v-0af845aa] {
  position: absolute;
  top: 16px;
  right: 16px;
}
.titleBox[data-v-0af845aa] {
  font-size: 20px;
  color: #333333;
  font-weight: bolder;
  display: block;
}
.contentBox[data-v-0af845aa] {
  color: #999999;
  font-size: 12px;
  text-align: center;
}
.contentBox div[data-v-0af845aa] {
  width: 100px;
  text-align: right;
}
.bottomBox[data-v-0af845aa] {
  width: calc(100% - 16px);
  /*background-color: rgba(64, 158, 255, 0.55);*/
  border: 2px solid #f4f4f5;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.stateBox[data-v-0af845aa] {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 10px 6px;
}
.timeBox[data-v-0af845aa] {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 10px 6px;
}
.el-dropdown + .el-dropdown[data-v-0af845aa] {
  margin-left: 15px;
}
.el-icon-arrow-down[data-v-0af845aa] {
  font-size: 12px;
}
.deviceCard[data-v-0af845aa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 18px;
  /*position: absolute;*/
  /*bottom: 2%;*/
  /*right: 2%;*/
}
th[data-v-0af845aa], td[data-v-0af845aa] {
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 12px;
}
.time[data-v-0af845aa] {
  font-size: 13px;
  color: #999;
}
.bottom[data-v-0af845aa] {
  margin-top: 13px;
  line-height: 12px;
}
.button[data-v-0af845aa] {
  padding: 0;
  float: right;
}
.clearfix[data-v-0af845aa]:before,
.clearfix[data-v-0af845aa]:after {
  display: table;
  content: "";
}
.clearfix[data-v-0af845aa]:after {
  clear: both
}

